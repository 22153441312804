import React, { useEffect, useContext } from "react";
import { Link } from "gatsby";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import Helmet from "react-helmet";
import Media from "react-media";

// Context
import WindowWidthContext from "../../components/context/window-width";
import { FooterColor } from "../../components/context/footer-color";

// Components
import StoreContext from "../../components/context/store";
import Layout from "../../components/account/Layout";
import DeleteAddress from "../../components/account/addresses/deleteAddress";
import { MobileAccountNavigation } from "../../components/account/mobile-account-navigation";
import { AccountNavigation } from "../../components/account/account-navigation";
import { Spacer } from "../../components/utils/spacer";

import {
	Page,
	AccountContainer,
	ContentContainer,
} from "../../components/trade/trade-components";

import { tertiary } from "../../components/utils/colors";

const SingleAddress = styled.div`
	margin: 0 0 40px 0;

	& p {
		margin: 0;
	}
	& .button-container {
		& button {
			margin: 0 0 0 20px;
		}
	}
`;

const CUSTOMER_ADDRESS = gql`
	query($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			tags
			defaultAddress {
				id
				address1
				address2
				city
				phone
				lastName
				firstName
				country
				name
				zip
			}
			addresses(first: 10) {
				edges {
					node {
						id
						address1
						address2
						city
						phone
						lastName
						firstName
						country
						name
						zip
					}
				}
			}
		}
	}
`;

const Addresses = () => {
	const { customerAccessToken } = useContext(StoreContext);
	const windowWidth = useContext(WindowWidthContext);

	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	return (
		<Layout>
			<Query
				query={CUSTOMER_ADDRESS}
				variables={{
					customerAccessToken: customerAccessToken.accessToken,
				}}
				fetchPolicy="no-cache"
			>
				{({ loading, error, data }) => {
					if (loading) {
						return (
							<Page>
								<Media
									queries={{
										medium: "(min-width: 1025px)",
									}}
									defaultMatches={{
										medium: windowWidth === 1025,
									}}
									render={() => (
										<AccountNavigation position={`relative`} top={0} />
									)}
								/>{" "}
								<AccountContainer>
									<ContentContainer>
										<p className="margin-0">Fetching</p>
									</ContentContainer>
								</AccountContainer>
							</Page>
						);
					}
					if (error) {
						return (
							<Page>
								<Media
									queries={{
										medium: "(min-width: 1025px)",
									}}
									defaultMatches={{
										medium: windowWidth === 1025,
									}}
									render={() => (
										<AccountNavigation position={`relative`} top={0} />
									)}
								/>{" "}
								<AccountContainer>
									<ContentContainer>
										<p className="margin-0">
											Error loading page. Please try refreshing.
										</p>
									</ContentContainer>
								</AccountContainer>
							</Page>
						);
					}

					const { defaultAddress, addresses } = data.customer;

					return (
						<Page>
							<Helmet title={`Addresses | Christian Watson`} />

							<Media
								queries={{
									medium: "(min-width: 1025px)",
								}}
								defaultMatches={{
									medium: windowWidth === 1025,
								}}
								render={() => (
									<AccountNavigation position={`relative`} top={0} />
								)}
							/>

							<Media
								queries={{
									medium: "(max-width: 1024px)",
								}}
								defaultMatches={{
									medium: windowWidth === 1024,
								}}
								render={() => (
									<MobileAccountNavigation position={`relative`} top={0} />
								)}
							/>

							<AccountContainer>
								<ContentContainer>
									{defaultAddress !== null && (
										<div className="text-column">
											<h1 className="caps">Default Address</h1>
											<SingleAddress key={defaultAddress.id}>
												<p>
													{defaultAddress.firstName} {defaultAddress.lastName}
												</p>
												<p>{defaultAddress.address1}</p>
												<p>{defaultAddress.address2}</p>
												<p>
													{defaultAddress.city} {defaultAddress.zip}
												</p>
												<p>{defaultAddress.country}</p>
												<p>{defaultAddress.phone}</p>
												<div className="button-container">
													<Link
														to={`/account/edit-address`}
														state={{
															addressToEdit: defaultAddress,
														}}
														className="link"
													>
														Edit
													</Link>
													<DeleteAddress id={defaultAddress.id} />
												</div>
											</SingleAddress>
										</div>
									)}

									<h1 className="caps">Saved Addresses</h1>
									{addresses != null &&
										addresses.edges.map((address) => {
											const addressToEdit = address.node;
											return (
												<SingleAddress key={address.node.id}>
													<p>
														{address.node.firstName} {address.node.lastName}
													</p>
													<p>{address.node.address1}</p>
													<p>{address.node.address2}</p>
													<p>
														{address.node.city} {defaultAddress.zip}
													</p>
													<p>{address.node.country}</p>
													<p>{address.node.phone}</p>
													<div className="button-container">
														<Link
															to={`/account/edit-address`}
															state={{
																addressToEdit: addressToEdit,
															}}
															className="link"
														>
															Edit
														</Link>
														<DeleteAddress id={address.node.id} />
													</div>
												</SingleAddress>
											);
										})}
									<Link to={`/account/add-address`} className="link">
										Add Address
									</Link>
								</ContentContainer>
								<Spacer />
							</AccountContainer>
						</Page>
					);
				}}
			</Query>
		</Layout>
	);
};

export default Addresses;
